import React, { useState } from "react";
import { WithClass } from "../../hoc/WithClass";
import HeaderStatic from "../../components/UI/HeaderStatic/HeaderStatic";
import Icon from "../../components/UI/Icon/Icon";
import SkillsPicture from "../../assets/icons/skills.png";
import TechnologiesPicture from "../../assets/icons/technologies.png";
import WavePicture from "../../assets/icons/wave.png";
import DegreePicture from "../../assets/icons/degree.png";
import IphonePicture from "../../assets/icons/iphone.png";
import HikingPicture from "../../assets/icons/hiking.png";
import CameraPicture from "../../assets/icons/camera.png";
import ContactPicture from "../../assets/icons/contact.png";
import DownPicture from "../../assets/icons/down.png";
import HeaderRightNavbutton from "../../components/UI-Mobile/HeaderRightNavbutton/HeaderRightNavbutton";
import RightDrawerSlideIn from "../../components/UI-Mobile/RightDrawerSlideIn/RightDrawerSlideIn";
import HeaderRightXButton from "../../components/UI-Mobile/HeaderRightXButton/HeaderRightXButton";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

import Picture from "../../assets/profile-picture.png";

import classes from "./AboutPage.module.css";

const AboutPage = () => {
  const [ref, inView] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();
  const [ref6, inView6] = useInView();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderHeader = () => {
    if (isMobile) {
      return (
        <WithClass className={classes.HeaderContainer}>
          <WithClass className={classes.Header}>
            <img src={Picture} className={classes.HeaderPicturePhoneOnly} />
            <WithClass className={classes.NavContainer}>
              {isDrawerOpen ? (
                <HeaderRightXButton onClick={() => setIsDrawerOpen(false)} />
              ) : (
                <HeaderRightNavbutton onClick={() => setIsDrawerOpen(true)} />
              )}
            </WithClass>
          </WithClass>
        </WithClass>
      );
    } else {
      return (
        <WithClass className={classes.HeaderContainer}>
          <HeaderStatic className={classes.Header} />
        </WithClass>
      );
    }
  };

  return (
    <WithClass className={classes.MainContainer}>
      {isDrawerOpen ? (
        <RightDrawerSlideIn nonDrawerClick={() => setIsDrawerOpen(false)} />
      ) : null}
      {renderHeader()}
      <WithClass className={classes.BodyContainer}>
        <WithClass className={classes.PictureContainer}>
          <img src={Picture} className={classes.Picture} />
        </WithClass>
        <WithClass className={classes.SubBodyContainer1}>
          <WithClass className={classes.SubBodyContainer}>
            <WithClass className={classes.Seperator1} />
            <div
              ref={ref}
              tag="p"
              className={inView ? classes.Paragraph1 : classes.Paragraph1Hidden}
            >
              <Icon imageStyle={classes.Wave} src={WavePicture} />
              Hey! Thanks for visiting my website!
            </div>
            <div
              className={inView ? classes.ScrollMore : classes.ScrollMoreHidden}
            >
              Scroll to see more!
              <Icon
                imageStyle={inView ? classes.Down : classes.DownHidden}
                src={DownPicture}
              />
            </div>
            <WithClass className={classes.Seperator} />
            <div
              ref={ref2}
              tag="p"
              className={
                inView2 ? classes.Paragraph2 : classes.Paragraph2Hidden
              }
            >
              <Icon imageStyle={classes.Degree} src={DegreePicture} />A little
              about myself: I'm a software engineer that graduated from the{" "}
              <a href="https://www.ucsc.edu/" className={classes.SantaCruzLink}>
                University of California, Santa Cruz
              </a>{" "}
              with a major in Cognitive Science. Which means I took upper
              division classes in Psychology as well as upper division classes
              in Computer Science.
            </div>
            <WithClass className={classes.Seperator} />
            <div
              ref={ref3}
              tag="p"
              className={
                inView3 ? classes.Paragraph3 : classes.Paragraph3Hidden
              }
            >
              <Icon imageStyle={classes.Iphone} src={IphonePicture} />
              I'm currently working on a mobile application called{" "}
              <a
                href="https://github.com/cwnicoletti/ExhibitU"
                className={classes.ExhibitULink}
              >
                "ExhibitU"
              </a>{" "}
              which is an app that allows users to showcase their talents,
              skills and abilities in an organized and social environment.
              Currently this app is in beta on TestFlight.
            </div>
            <WithClass className={classes.Seperator} />
            <div
              ref={ref4}
              tag="p"
              className={
                inView4 ? classes.Paragraph4 : classes.Paragraph4Hidden
              }
            >
              Here are my current skills and the technologies that I have
              experience using:
              <WithClass className={classes.SkillsAndTechnologyContainer}>
                <WithClass className={classes.SkillContainer}>
                  <WithClass className={classes.SkillIconTextContainer}>
                    <Icon src={SkillsPicture} />
                    <h2 className={classes.SkillText}>Skills:</h2>
                  </WithClass>
                  <WithClass className={classes.SkillLists}>
                    <ul>
                      <li>Javascript/Typescript</li>
                      <li>React</li>
                      <li>React Native</li>
                      <li>Node.js</li>
                    </ul>
                    <ul>
                      <li>Jest (Unit Testing)</li>
                      <li>JSON</li>
                      <li>HTML/CSS</li>
                      <li>Nonrelational databases</li>
                    </ul>
                  </WithClass>
                </WithClass>
                <WithClass className={classes.SkillContainer}>
                  <WithClass className={classes.SkillIconTextContainer}>
                    <Icon src={TechnologiesPicture} />
                    <h2 className={classes.SkillText}>Technologies:</h2>
                  </WithClass>
                  <WithClass className={classes.SkillLists}>
                    <ul>
                      <li>VSCode</li>
                      <li>Git</li>
                      <li>Xcode</li>
                      <li>Android Studio</li>
                    </ul>
                    <ul>
                      <li>GCS (Firebase)</li>
                      <li>Codacy</li>
                      <li>Algolia</li>
                      <li>Cloudinary</li>
                    </ul>
                  </WithClass>
                </WithClass>
              </WithClass>
            </div>
            <WithClass className={classes.Seperator} />
            <div
              ref={ref5}
              tag="p"
              className={
                inView5 ? classes.Paragraph5 : classes.Paragraph5Hidden
              }
            >
              <WithClass className={classes.FreeTimeIcons}>
                <Icon imageStyle={classes.Hiking} src={HikingPicture} />
                <Icon imageStyle={classes.Camera} src={CameraPicture} />
              </WithClass>
              In my free time, I enjoy photography with my{" "}
              <a
                href="https://www.nikonusa.com/en/nikon-products/product-archive/dslr-cameras/d7100.html"
                className={classes.CameraLink}
              >
                Nikon D7100
              </a>{" "}
              and hiking pretty much anywhere, as well as playing video games
              and watching movies.
              <br />
              <br />
            </div>
            <WithClass className={classes.Seperator} />
            <div
              ref={ref6}
              tag="p"
              className={
                inView6 ? classes.Paragraph6 : classes.Paragraph6Hidden
              }
            >
              <Icon imageStyle={classes.Contact} src={ContactPicture} />
              If you'd like to get to know more about me or talk about a
              business opportunity, head over to my contact page to reach out to
              me!
              <WithClass className={classes.LastSeperator} />
            </div>
          </WithClass>
        </WithClass>
      </WithClass>
    </WithClass>
  );
};

export default AboutPage;
