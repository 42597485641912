import React from "react";
import { NavLink } from "react-router-dom";
import { WithClass } from "../../../hoc/WithClass";

import classes from "./RightDrawerSlideIn.module.css";

const RightDrawerSlideIn = (props) => {
  return (
    <WithClass className={`${props.className}`}>
      <div
        onClick={props.nonDrawerClick}
        className={`${classes.NonDrawerContainer} ${props.nonDrawerContainerStyle}`}
      />
      <WithClass
        className={`${classes.DrawerContainer} ${props.drawerContainerStyle}`}
      >
        <NavLink to="/" className={classes.BarItem1}>
          <h3 className={classes.TextItem1}>
            Home
            <div className={classes.underlineEffect1} />
          </h3>
        </NavLink>
        <NavLink to="/about" className={classes.BarItem2}>
          <h3 className={classes.TextItem2}>
            About
            <div className={classes.underlineEffect2} />
          </h3>
        </NavLink>
        <NavLink to="/work" className={classes.BarItem3}>
          <h3 className={classes.TextItem3}>
            Work
            <div className={classes.underlineEffect3} />
          </h3>
        </NavLink>
        <NavLink to="/photography" className={classes.BarItem4}>
          <h3 className={classes.TextItem4}>
            Photography
            <div className={classes.underlineEffect4} />
          </h3>
        </NavLink>
        <NavLink to="/contact" className={classes.BarItem5}>
          <h3 className={classes.TextItem5}>
            Contact
            <div className={classes.underlineEffect5} />
          </h3>
        </NavLink>
      </WithClass>
    </WithClass>
  );
};

export default RightDrawerSlideIn;
