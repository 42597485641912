import React, { useEffect, useState } from "react";
import { WithClass } from "../../hoc/WithClass";
import AnimatedSlideShowText from "../../components/UI/AnimatedSlideShowText/AnimatedSlideShowText";
import HeaderAnimated from "../../components/UI/HeaderAnimated/HeaderAnimated";
import HeaderStatic from "../../components/UI/HeaderStatic/HeaderStatic";
import HeaderRightNavbutton from "../../components/UI-Mobile/HeaderRightNavbutton/HeaderRightNavbutton";
import RightDrawerSlideIn from "../../components/UI-Mobile/RightDrawerSlideIn/RightDrawerSlideIn";
import HeaderRightXButton from "../../components/UI-Mobile/HeaderRightXButton/HeaderRightXButton";
import SocialMediaFooter from "../../components/UI/SocialMediaFooter/SocialMediaFooter";
import { useDispatch, useSelector } from "react-redux";
import { loadedOnce } from "../../store/actions/user";
import Picture from "../../assets/profile-picture.png";
import { isMobile } from "react-device-detect";

import classes from "./HomePage.module.css";

const HomePage = () => {
  const dispatch = useDispatch();
  const didLoadOnce = useSelector((state) => state.user.loadedOnce);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch(loadedOnce());
    }, 2000);
  });

  const renderHeader = () => {
    if (isMobile) {
      return (
        <WithClass className={classes.HeaderContainer}>
          <WithClass className={classes.Header}>
            <img src={Picture} className={classes.HeaderPicturePhoneOnly} />
            <WithClass className={classes.NavContainer}>
              {isDrawerOpen ? (
                <HeaderRightXButton onClick={() => setIsDrawerOpen(false)} />
              ) : (
                <HeaderRightNavbutton onClick={() => setIsDrawerOpen(true)} />
              )}
            </WithClass>
          </WithClass>
        </WithClass>
      );
    } else {
      return (
        <WithClass className={classes.HeaderContainer}>
          {didLoadOnce ? (
            <HeaderStatic className={classes.Header} />
          ) : (
            <HeaderAnimated className={classes.Header} />
          )}
        </WithClass>
      );
    }
  };

  return (
    <WithClass className={classes.MainContainer}>
      {isDrawerOpen ? (
        <RightDrawerSlideIn nonDrawerClick={() => setIsDrawerOpen(false)} />
      ) : null}
      {renderHeader()}
      <WithClass className={classes.BodyContainer}>
        <WithClass className={classes.NameContainer}>
          <AnimatedSlideShowText tag="h1" mainTextStyle={classes.Name}>
            Christian Nicoletti
          </AnimatedSlideShowText>
          <AnimatedSlideShowText tag="h2" mainTextStyle={classes.Title}>
            Software Engineer
          </AnimatedSlideShowText>
          <AnimatedSlideShowText tag="h3" mainTextStyle={classes.School}>
            University of California, Santa Cruz graduate
          </AnimatedSlideShowText>
        </WithClass>
        <WithClass className={classes.FooterContainer}>
          <SocialMediaFooter />
        </WithClass>
      </WithClass>
    </WithClass>
  );
};

export default HomePage;
