import React from "react";

import { WithClass } from "../../../hoc/WithClass";
import classes from "./Icon.module.css";

const Icon = (props) => {
  return (
    <WithClass className={classes.pictureContainer}>
      <img src={props.src} className={`${classes.picture} ${props.imageStyle}`} />
    </WithClass>
  );
};

export default Icon;
