import React, { useState } from "react";
import { WithClass } from "../../hoc/WithClass";
import AnimatedSlideShowText from "../../components/UI/AnimatedSlideShowText/AnimatedSlideShowText";
import AnimatedSlideShowCard from "../../components/UI/AnimatedSlideShowCard/AnimatedSlideShowCard";
import AnimatedBarWidth from "../../components/UI/AnimatedBarWidth/AnimatedBarWidth";
import HeaderRightNavbutton from "../../components/UI-Mobile/HeaderRightNavbutton/HeaderRightNavbutton";
import RightDrawerSlideIn from "../../components/UI-Mobile/RightDrawerSlideIn/RightDrawerSlideIn";
import HeaderRightXButton from "../../components/UI-Mobile/HeaderRightXButton/HeaderRightXButton";
import GithubLogo from "../../assets/github-logo.png";
import CadlabLogo from "../../assets/cadlab-logo.png";
import HeaderStatic from "../../components/UI/HeaderStatic/HeaderStatic";
import Picture from "../../assets/profile-picture.png";
import { isMobile } from "react-device-detect";

import classes from "./WorkPage.module.css";

const WorkPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const scaleFactor = 500 / 200;
  let imageHeight = 500 / scaleFactor;
  const height = imageHeight;
  const projects = [
    {
      id: 1,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1627322354/image1_nzjygj.png",
      website: "https://github.com/cwnicoletti/ExhibitU",
      title: "ExhibitU",
      description:
        "A mobile app designed for sharing projects with friends, family, and colleagues ",
      technologies: [
        "React Native",
        "React-Redux",
        "Node.js",
        "Typescript",
        "Javascript",
        "Jest",
        "Firebase",
        "Algolia",
        "Cloudinary",
      ],
      links: [
        {
          linkUrl: "https://github.com/cwnicoletti/ExhibitU",
          icon: GithubLogo,
        },
      ],
    },
    {
      id: 2,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1617215999/CoCreatorwebapp_lzqnin.png",
      website: "https://github.com/cwnicoletti/Co-Creator",
      title: "Co-Creator",
      description: "Super rad project hosting web app ",
      technologies: [
        "React",
        "Node.js",
        "Javascript",
        "HTML/CSS",
        "Firebase",
        "Adobe XD",
      ],
      links: [
        {
          linkUrl: "https://github.com/cwnicoletti/Co-Creator",
          icon: GithubLogo,
        },
      ],
    },
    {
      id: 3,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1617216611/eegbreadboard_lnqsya.png",
      website: "https://github.com/cwnicoletti/Electroencephalogram-Project",
      title: "Electroencephalogram Project",
      description: "Creating an EEG from scratch ",
      technologies: ["Python", "Java", "KiCad", "CadLab"],
      links: [
        {
          linkUrl:
            "https://github.com/cwnicoletti/Electroencephalogram-Project",
          icon: GithubLogo,
        },
        {
          linkUrl: "https://cadlab.io/project/1532/master/files",
          icon: CadlabLogo,
        },
      ],
    },
  ];

  const renderHeader = () => {
    if (isMobile) {
      return (
        <WithClass className={classes.HeaderContainer}>
          <WithClass className={classes.Header}>
            <img src={Picture} className={classes.HeaderPicturePhoneOnly} />
            <WithClass className={classes.NavContainer}>
              {isDrawerOpen ? (
                <HeaderRightXButton onClick={() => setIsDrawerOpen(false)} />
              ) : (
                <HeaderRightNavbutton onClick={() => setIsDrawerOpen(true)} />
              )}
            </WithClass>
          </WithClass>
        </WithClass>
      );
    } else {
      return (
        <WithClass className={classes.HeaderContainer}>
          <HeaderStatic className={classes.Header} />
        </WithClass>
      );
    }
  };

  return (
    <WithClass className={classes.MainContainer}>
      {isDrawerOpen ? (
        <RightDrawerSlideIn nonDrawerClick={() => setIsDrawerOpen(false)} />
      ) : null}
      {renderHeader()}
      <WithClass className={classes.NameContainer}>
        <AnimatedSlideShowText tag="h1" mainTextStyle={classes.Title}>
          Portfolio
        </AnimatedSlideShowText>
        <WithClass className={classes.BarContainer}>
          <AnimatedBarWidth BarStyle={classes.Bar} />
        </WithClass>
      </WithClass>
      <WithClass className={classes.BodyContainer}>
        <WithClass className={classes.GalleryContainer}>
          {projects.map((project) => (
            <div
              key={project.id}
              href={project.website}
              className={classes[`PictureContainer${project.id}`]}
            >
              <AnimatedSlideShowCard
                CardContainer={classes[`CardContainer${project.id}`]}
                mainPictureStyle={classes[`Picture${project.id}`]}
                width={200}
                height={height}
                imgSrc={project.url}
              >
                <WithClass className={classes.CardContentsContainer}>
                  <WithClass className={classes.CardTextContainer}>
                    <p className={classes[`ProjectTitle${project.id}`]}>
                      {project.title}
                    </p>
                    <p className={classes[`ProjectDescription${project.id}`]}>
                      {project.description}
                    </p>
                    <WithClass className={classes.TechContainer}>
                      {project.technologies.map((tech) => (
                        <div className={classes.Tech}>{tech}</div>
                      ))}
                    </WithClass>
                  </WithClass>
                  <WithClass className={classes.LinkContainer}>
                    {project.links.map((link) => (
                      <a href={link.linkUrl} className={classes.Link}>
                        <img src={link.icon} className={classes.Logo} />
                      </a>
                    ))}
                  </WithClass>
                </WithClass>
              </AnimatedSlideShowCard>
            </div>
          ))}
          <WithClass className={classes.Seperator} />
        </WithClass>
      </WithClass>
    </WithClass>
  );
};

export default WorkPage;
