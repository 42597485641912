import React from "react";
import { NavLink } from "react-router-dom";

import { WithClass } from "../../../hoc/WithClass";
import classes from "./HeaderStatic.module.css";

const HeaderStatic = (props) => {
  return (
    <WithClass className={`${classes.HeaderContainer} ${props.className}`}>
      <NavLink to="/" className={classes.BarItem1}>
        <h3>
          Home
          <div className={classes.underlineEffect1} />
        </h3>
      </NavLink>
      <NavLink to="/about" className={classes.BarItem2}>
        <h3>
          About
          <div className={classes.underlineEffect2} />
        </h3>
      </NavLink>
      <NavLink to="/work" className={classes.BarItem3}>
        <h3>
          Work
          <div className={classes.underlineEffect3} />
        </h3>
      </NavLink>
      <NavLink to="/photography" className={classes.BarItem4}>
        <h3>
          Photography
          <div className={classes.underlineEffect4} />
        </h3>
      </NavLink>
      <NavLink to="/contact" className={classes.BarItem5}>
        <h3>
          Contact
          <div className={classes.underlineEffect5} />
        </h3>
      </NavLink>
    </WithClass>
  );
};

export default HeaderStatic;
