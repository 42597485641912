import React, { useState } from "react";
import { WithClass } from "../../hoc/WithClass";
import AnimatedSlideShowText from "../../components/UI/AnimatedSlideShowText/AnimatedSlideShowText";
import AnimatedSlideShowPicture from "../../components/PhotographyPage/AnimatedSlideShowPicture/AnimatedSlideShowPicture";
import AnimatedBarWidth from "../../components/UI/AnimatedBarWidth/AnimatedBarWidth";
import HeaderStatic from "../../components/UI/HeaderStatic/HeaderStatic";
import HeaderRightNavbutton from "../../components/UI-Mobile/HeaderRightNavbutton/HeaderRightNavbutton";
import RightDrawerSlideIn from "../../components/UI-Mobile/RightDrawerSlideIn/RightDrawerSlideIn";
import HeaderRightXButton from "../../components/UI-Mobile/HeaderRightXButton/HeaderRightXButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Picture from "../../assets/profile-picture.png";
import { isMobile } from "react-device-detect";

import classes from "./PhotographyPage.module.css";

const PhotographyPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { width } = useWindowDimensions();
  const imageWidth = width / 5;

  const oneColumnImages = [
    {
      id: 1,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/IMG_0131_1_q5chcd_wu1tak.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/IMG_0131_1_q5chcd_wu1tak.jpg",
    },
    {
      id: 2,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2281_1_lhtd9d_1_nutpdu.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2281_1_lhtd9d_1_nutpdu.jpg",
    },
    {
      id: 4,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9946_1_1_1_orxzbj_bwdygy.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9946_1_1_1_orxzbj_bwdygy.jpg",
    },
    {
      id: 5,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9771_1_b7tabl_1_qdblv1.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9771_1_b7tabl_1_qdblv1.jpg",
    },
    {
      id: 6,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_0475_snci2d_1_lbhn8f.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_0475_snci2d_1_lbhn8f.jpg",
    },
    {
      id: 7,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_0568_aqx9jz_1_g63wgz.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_0568_aqx9jz_1_g63wgz.jpg",
    },
    {
      id: 8,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2374-min_1_2_duhaka_1_u4enzg.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2374-min_1_2_duhaka_1_u4enzg.jpg",
    },
    {
      id: 9,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_6119_2_yyfvap_1_wzaxtc.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_6119_2_yyfvap_1_wzaxtc.jpg",
    },
  ];

  const columnImages1 = [
    {
      id: 1,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/IMG_0131_1_q5chcd_wu1tak.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/IMG_0131_1_q5chcd_wu1tak.jpg",
    },
    {
      id: 5,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_0475_snci2d_1_lbhn8f.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_0475_snci2d_1_lbhn8f.jpg",
    },
  ];

  const columnImages2 = [
    {
      id: 2,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2281_1_lhtd9d_1_nutpdu.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2281_1_lhtd9d_1_nutpdu.jpg",
    },
    {
      id: 6,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_0568_aqx9jz_1_g63wgz.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_0568_aqx9jz_1_g63wgz.jpg",
    },
  ];

  const columnImages3 = [
    {
      id: 3,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9946_1_1_1_orxzbj_bwdygy.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9946_1_1_1_orxzbj_bwdygy.jpg",
    },
    {
      id: 7,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2374-min_1_2_duhaka_1_u4enzg.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183615/photography/DSC_2374-min_1_2_duhaka_1_u4enzg.jpg",
    },
  ];

  const columnImages4 = [
    {
      id: 4,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9771_1_b7tabl_1_qdblv1.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183616/photography/DSC_9771_1_b7tabl_1_qdblv1.jpg",
    },
    {
      id: 8,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_8850_1_hogjl2_1_gbm8rs.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_8850_1_hogjl2_1_gbm8rs.jpg",
    },
    {
      id: 9,
      url: "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_6119_2_yyfvap_1_wzaxtc.jpg",
      website:
        "https://res.cloudinary.com/personaluse1234/image/upload/v1635183614/photography/DSC_6119_2_yyfvap_1_wzaxtc.jpg",
    },
  ];

  const renderHeader = () => {
    if (isMobile) {
      return (
        <WithClass className={classes.HeaderContainer}>
          <WithClass className={classes.Header}>
            <img src={Picture} className={classes.HeaderPicturePhoneOnly} />
            <WithClass className={classes.NavContainer}>
              {isDrawerOpen ? (
                <HeaderRightXButton onClick={() => setIsDrawerOpen(false)} />
              ) : (
                <HeaderRightNavbutton onClick={() => setIsDrawerOpen(true)} />
              )}
            </WithClass>
          </WithClass>
        </WithClass>
      );
    } else {
      return (
        <WithClass className={classes.HeaderContainer}>
          <HeaderStatic className={classes.Header} />
        </WithClass>
      );
    }
  };

  const renderGallery = () => {
    if (isMobile) {
      return (
        <WithClass className={classes.GalleryContainer}>
          <WithClass className={classes.Column1}>
            {oneColumnImages.map((image) => (
              <a
                key={image.id}
                href={image.website}
                className={classes[`PictureContainer${image.id}`]}
              >
                <AnimatedSlideShowPicture
                  mainPictureStyle={classes[`Picture${image.id}`]}
                  width={"100%"}
                  imgSrc={image.url}
                />
              </a>
            ))}
            <WithClass className={classes.Seperator} />
          </WithClass>
        </WithClass>
      );
    } else {
      return (
        <WithClass className={classes.GalleryContainer}>
          <WithClass className={classes.Column1}>
            {columnImages1.map((image) => (
              <a
                key={image.id}
                href={image.website}
                className={classes[`PictureContainer${image.id}`]}
              >
                <AnimatedSlideShowPicture
                  mainPictureStyle={classes[`Picture${image.id}`]}
                  width={imageWidth}
                  imgSrc={image.url}
                />
              </a>
            ))}
          </WithClass>
          <WithClass className={classes.Column2}>
            {columnImages2.map((image) => (
              <a
                key={image.id}
                href={image.website}
                className={classes[`PictureContainer${image.id}`]}
              >
                <AnimatedSlideShowPicture
                  mainPictureStyle={classes[`Picture${image.id}`]}
                  width={imageWidth}
                  imgSrc={image.url}
                />
              </a>
            ))}
          </WithClass>
          <WithClass className={classes.Column3}>
            {columnImages3.map((image) => (
              <a
                key={image.id}
                href={image.website}
                className={classes[`PictureContainer${image.id}`]}
              >
                <AnimatedSlideShowPicture
                  mainPictureStyle={classes[`Picture${image.id}`]}
                  width={imageWidth}
                  imgSrc={image.url}
                />
              </a>
            ))}
          </WithClass>
          <WithClass className={classes.Column4}>
            {columnImages4.map((image) => (
              <a
                key={image.id}
                href={image.website}
                className={classes[`PictureContainer${image.id}`]}
              >
                <AnimatedSlideShowPicture
                  mainPictureStyle={classes[`Picture${image.id}`]}
                  width={imageWidth}
                  imgSrc={image.url}
                />
              </a>
            ))}
          </WithClass>
        </WithClass>
      );
    }
  };

  return (
    <WithClass className={classes.MainContainer}>
      {isDrawerOpen ? (
        <RightDrawerSlideIn nonDrawerClick={() => setIsDrawerOpen(false)} />
      ) : null}
      {renderHeader()}
      <WithClass className={classes.NameContainer}>
        <AnimatedSlideShowText tag="h1" mainTextStyle={classes.Title}>
          Gallery
        </AnimatedSlideShowText>
        <WithClass className={classes.BarContainer}>
          <AnimatedBarWidth BarStyle={classes.Bar} />
        </WithClass>
      </WithClass>
      <WithClass className={classes.BodyContainer}>{renderGallery()}</WithClass>
    </WithClass>
  );
};

export default PhotographyPage;
