export const LOADED_ONCE = "LOADED_ONCE";
export const CURRENT_PAGE = "CURRENT_PAGE";

export const loadedOnce = () => {
  return async (dispatch) => {
    await dispatch({ type: LOADED_ONCE });
  };
};

export const currentPage = (pageName) => {
  return async (dispatch) => {
    await dispatch({ type: CURRENT_PAGE, pageName });
  };
};
