import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import WorkPage from "./pages/WorkPage/WorkPage";
import PhotographyPage from "./pages/PhotographyPage/PhotographyPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import { WithClass } from "./hoc/WithClass";

/**
 * Contains switch routing to components
 *
 * Called by index.js in ReactDOM.render()
 */
const App = () => {
  return (
    <WithClass>
      <Switch>
        <Route path="/about" exact component={AboutPage} />
        <Route path="/work" exact component={WorkPage} />
        <Route path="/photography" exact component={PhotographyPage} />
        <Route path="/contact" exact component={ContactPage} />
        <Route path="/" exact component={HomePage} />
        <Redirect to="/" />
        {/* Redirect anything other than routes specified to "/" */}
      </Switch>
    </WithClass>
  );
};

export default withRouter(App);
