import React, { useState } from "react";
import { WithClass } from "../../hoc/WithClass";
import AnimatedSlideShowText from "../../components/UI/AnimatedSlideShowText/AnimatedSlideShowText";
import HeaderStatic from "../../components/UI/HeaderStatic/HeaderStatic";
import SocialMediaFooter from "../../components/UI/SocialMediaFooter/SocialMediaFooter";
import HeaderRightNavbutton from "../../components/UI-Mobile/HeaderRightNavbutton/HeaderRightNavbutton";
import RightDrawerSlideIn from "../../components/UI-Mobile/RightDrawerSlideIn/RightDrawerSlideIn";
import HeaderRightXButton from "../../components/UI-Mobile/HeaderRightXButton/HeaderRightXButton";
import Picture from "../../assets/profile-picture.png";
import { isMobile } from "react-device-detect";

import classes from "./ContactPage.module.css";

const ContactPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderHeader = () => {
    if (isMobile) {
      return (
        <WithClass className={classes.HeaderContainer}>
          <WithClass className={classes.Header}>
            <img src={Picture} className={classes.HeaderPicturePhoneOnly} />
            <WithClass className={classes.NavContainer}>
              {isDrawerOpen ? (
                <HeaderRightXButton onClick={() => setIsDrawerOpen(false)} />
              ) : (
                <HeaderRightNavbutton onClick={() => setIsDrawerOpen(true)} />
              )}
            </WithClass>
          </WithClass>
        </WithClass>
      );
    } else {
      return (
        <WithClass className={classes.HeaderContainer}>
          <HeaderStatic className={classes.Header} />
        </WithClass>
      );
    }
  };

  return (
    <WithClass className={classes.MainContainer}>
      {isDrawerOpen ? (
        <RightDrawerSlideIn nonDrawerClick={() => setIsDrawerOpen(false)} />
      ) : null}
      {renderHeader()}
      <WithClass className={classes.BodyContainer}>
        <WithClass className={classes.NameContainer}>
          <AnimatedSlideShowText tag="h1">Contact</AnimatedSlideShowText>
          <AnimatedSlideShowText tag="h3" mainTextStyle={classes.ShortIntro}>
            My preferred form of communication is email, but feel free to
            contact me on other platforms as well
          </AnimatedSlideShowText>
          <AnimatedSlideShowText tag="h3" mainTextStyle={classes.Email}>
            Gmail: cwnicoletti@gmail.com
          </AnimatedSlideShowText>
        </WithClass>
        <WithClass className={classes.FooterContainer}>
          <SocialMediaFooter />
        </WithClass>
      </WithClass>
    </WithClass>
  );
};

export default ContactPage;
