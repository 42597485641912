import React from "react";
import IndeedLogo from "../../../assets/indeed-logo.png";
import LinkedinLogo from "../../../assets/linkedin-logo.png";
import GithubLogo from "../../../assets/github-logo.png";
import StackoverflowLogo from "../../../assets/stackoverflow-logo.png";

import { WithClass } from "../../../hoc/WithClass";
import classes from "./SocialMediaFooter.module.css";

const SocialMediaFooter = () => {
  return (
    <row className={classes.container}>
      <a
        href="https://www.linkedin.com/in/cwnicoletti/"
        className={classes.logoContainer1}
      >
        <WithClass className={classes.logoTextContainer2}>
          <img src={LinkedinLogo} className={classes.logo1} />
          <div className={classes.logoText1}>LinkedIn</div>
        </WithClass>
      </a>
      <a
        href="https://github.com/cwnicoletti"
        className={classes.logoContainer2}
      >
        <WithClass className={classes.logoTextContainer2}>
          <img src={GithubLogo} className={classes.logo2} />
          <div className={classes.logoText2}>GitHub</div>
        </WithClass>
      </a>
      <a
        href="https://stackoverflow.com/users/11938071/christian-nicoletti"
        className={classes.logoContainer3}
      >
        <WithClass className={classes.logoTextContainer3}>
          <img src={StackoverflowLogo} className={classes.logo3} />
          <div className={classes.logoText3}>Stack Overflow</div>
        </WithClass>
      </a>
      <a
        href="https://my.indeed.com/p/christiann-icpn7p7"
        className={classes.logoContainer4}
      >
        <WithClass className={classes.logoTextContainer4}>
          <img src={IndeedLogo} className={classes.logo4} />
          <div className={classes.logoText4}>Indeed</div>
        </WithClass>
      </a>
    </row>
  );
};

export default SocialMediaFooter;
