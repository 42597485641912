import React from "react";
import classes from "./HeaderRightNavbutton.module.css";

const HeaderRightNavbutton = (props) => {
  return (
    <div
      className={`${classes.ButtonContainer} ${props.className}`}
      onClick={props.onClick}
    >
      <div className={classes.FirstBar} />
      <div className={classes.SecondBar} />
      <div className={classes.ThirdBar} />
    </div>
  );
};

export default HeaderRightNavbutton;
