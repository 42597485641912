import React from "react";
import classes from "./HeaderRightXButton.module.css";

const HeaderRightXButton = (props) => {
  return (
    <div onClick={props.onClick} className={classes.Close}>
      &times;
    </div>
  );
};

export default HeaderRightXButton;
