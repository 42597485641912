import React from "react";
import { WithClass } from "../../../hoc/WithClass";
import classes from "./AnimatedSlideShowCard.module.css";

const AnimatedSlideShowCard = (props) => {
  return (
    <WithClass className={`${classes.CardContainer} ${props.CardContainer}`}>
      <img
        className={`${classes.Picture} ${props.mainPictureStyle}`}
        height={props.height}
        width={props.width}
        src={props.imgSrc}
      />
      {props.children}
    </WithClass>
  );
};

export default AnimatedSlideShowCard;
