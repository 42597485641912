import React from "react";
import { WithClass } from "../../../hoc/WithClass";
import classes from "./AnimatedSlideShowPicture.module.css";

const AnimatedSlideShowPicture = (props) => {
  return (
    <WithClass className={classes.pictureContainer}>
      <img
        className={`${classes.Picture} ${props.mainPictureStyle}`}
        height={props.height}
        width={props.width}
        src={props.imgSrc}
      />
    </WithClass>
  );
};

export default AnimatedSlideShowPicture;
