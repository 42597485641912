import React, { forwardRef } from "react";
import classes from "./AnimatedSlideShowText.module.css";

const AnimatedSlideShowText = forwardRef((props, ref) => {
  const CustomTag = `${props.tag}`;
  return (
    <CustomTag
      ref={ref}
      className={`${classes.MainText} ${props.mainTextStyle}`}
    >
      {props.children}
    </CustomTag>
  );
});

export default AnimatedSlideShowText;
